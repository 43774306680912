import styled from "styled-components"

import { Container } from "../../global"

export const Nav = styled.nav`
  padding: 6px 0;
  width: 100%;
  z-index: 1000;
  background: white;
  box-shadow: rgb(85, 91, 255) 0px 0px 0px 3px, rgb(31, 193, 27) 0px 0px 0px 6px,
    rgb(255, 217, 19) 0px 0px 0px 9px, rgb(255, 156, 85) 0px 0px 0px 12px,
    rgb(255, 85, 85) 0px 0px 0px 15px;
`

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const NavListWrapper = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;

    ${({ mobile }) =>
      mobile &&
      `
        flex-direction: column;
        margin-top: 1em;

        > ${NavItem} {
          margin: 0;
          margin-top: 0.75em;
        }
      `};
  }
`

export const NavItem = styled.li`
  margin: 0 0.75em;
  font-family: ${(props) => props.theme.font.medium};
  ${(props) => props.theme.font_size.xsmall};

  a {
    text-decoration: none;
    opacity: 0.8;
    color: white;
  }

  &.active {
    a {
      opacity: 1;
    }
  }

  &:hover {
    a {
      text-decoration: underline;
      text-underline-offset: 8px;
    }
  }
`

export const MobileMenu = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background: ${(props) => props.theme.color.regular};
`

export const Brand = styled.div`
  font-family: ${(props) => props.theme.font.extrabold};
  ${(props) => props.theme.font_size.regular};
  color: ${(props) => props.theme.color.black.regular};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    a {
      color: ${(props) =>
        props.isDarkPage
          ? props.theme.color.white.regular
          : props.theme.color.black.regular};
      text-decoration: none;
    }
  }
`
export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${(props) => props.theme.screen.xs}) {
    display: none;
  }

  a {
    padding-right: 10px;
    text-decoration: none;
    color: ${(props) =>
      props.isDarkPage ? props.theme.color.white.regular : "black"};
    font-size: 15px;

    &:hover {
      text-decoration: underline;
      text-underline-offset: 5px;
    }
  }

  button {
    font-family: ${(props) => props.theme.font.normal};
    ${(props) => props.theme.font_size.xsmall};
    color: ${(props) =>
      props.isDarkPage
        ? props.theme.color.white.regular
        : props.theme.color.black.regular};
    background: ${(props) => (props.scrolled ? "#292929" : null)};
    margin: 5px;
    border: 1px solid black;
    font-weight: bold;
    padding: 10px 20px 10px 20px;
    font-size: 15px;
    transition: 0.2s cubic-bezier(0.4, 0.8, 0.3, 0.6);

    &:hover {
      background: black;
      color: white;
    }
  }
`

export const Mobile = styled.div`
  display: none;

  @media (max-width: ${(props) => props.theme.screen.xs}) {
    display: block;
  }

  ${(props) =>
    props.hide &&
    `
    display: block;

    @media (max-width: ${props.theme.screen.xs}) {
      display: none;
    }
  `}
`

// Background blur info
// background-color: ${props => props.scrolled && `rgba(245, 245, 250, .8`};
// box-shadow:  ${props =>
//   props.scrolled &&
//   `0 0 0 1px rgba(0,0,50,.02) inset, 0 1px 1px rgba(0,0,50,.05) inset, 0 2px 4px rgba(0,0,50,.04) inset`};
//   backdrop-filter: ${props => props.scrolled && `blur(15px)`};
