import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { EmailIcon } from "@chakra-ui/icons"
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react"
import { gql, useMutation } from "@apollo/client"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"

export default function SignupModal(ref) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  useImperativeHandle(ref, () => {
    onOpen: onOpen()
  })

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Modal Title</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          A huge amount of text is entered here and it's somehoe faster
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button variant="ghost">Secondary Action</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
SignupModal = forwardRef(SignupModal)
