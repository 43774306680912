import React, { Component } from "react"
import axios from "axios"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Scrollspy from "react-scrollspy"
import { Link } from "gatsby"
import { Menu, X } from "react-feather"
import { Divider } from "@chakra-ui/layout"
import { useDisclosure } from "@chakra-ui/react"

import { Container } from "../../global"
import {
  Nav,
  NavItem,
  Brand,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  Mobile,
  ActionsContainer,
} from "./style"
import SignupModal from "../../cards/SignupModal"

function getCurrentUser() {
  const user = netlifyIdentity.currentUser()
  console.log(user)
}

async function callServerlessLogin(email) {
  const response = await axios.post("/.netlify/functions/loginFauna", {
    email: email,
  })
  console.log(response)
}

async function callHelloWorld(text) {
  const response = await axios.post("/.netlify/functions/hello-world", {
    text: text,
  })
  console.log(response)
}

export default class Navigation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mobileMenuOpen: false,
      hasScrolled: false,
      NAV_ITEMS: props.items,
      isDarkPage: props.isDarkPage,
      button: props.blog,
    }
  }

  toggleMobileMenu = () => {
    this.setState((prevState) => ({
      mobileMenuOpen: !prevState.mobileMenuOpen,
    }))
  }

  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false })
    }
  }

  getNavAnchorLink = (item) => (
    <AnchorLink href={`#${item.toLowerCase()}`} onClick={this.closeMobileMenu}>
      {item}
    </AnchorLink>
  )

  getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      {this.state.NAV_ITEMS && (
        <Scrollspy
          items={this.state.NAV_ITEMS.map((item) => item.toLowerCase())}
          currentClassName="active"
          mobile={mobile}
          offset={-64}
        >
          {this.state.NAV_ITEMS.map((navItem) => (
            <NavItem
              isDarkPage={this.state.isDarkPage}
              key={navItem}
              scrolled={this.state.hasScrolled}
            >
              {this.getNavAnchorLink(navItem)}
            </NavItem>
          ))}
        </Scrollspy>
      )}
    </NavListWrapper>
  )

  render() {
    const { mobileMenuOpen } = this.state

    return (
      <Nav {...this.props}>
        <StyledContainer>
          <Brand isDarkPage={this.state.isDarkPage}>
            <Scrollspy offset={-64} item={["top"]} currentClassName="active">
              <Link to="/">My Immortality Project</Link>
            </Scrollspy>
          </Brand>
          <ActionsContainer isDarkPage={this.state.isDarkPage}>
            <Link>
              <a href="mailto:rohan@myimmortalityproject.com">
                Send us an email.
              </a>
            </Link>
            <Link to={"../../register/"}>
              <button>Sign Up Now</button>
            </Link>
          </ActionsContainer>
        </StyledContainer>
      </Nav>
    )
  }
}
